@import "../scss/pallete.scss";

@mixin paragraph($color:black, $font-size: 14px) {
  line-height: 2;
  font-size: $font-size;
  text-align: justify;
  color: $color;
}

html, body, * {
  font-family: 'yekan', sans-serif;
}

a, button {
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: default;
  }
}

.section-title {
  display: flex;
  background: transparent;
  width: fit-content;
  padding: 0 4rem 3rem;
  margin: 0 auto;

  &-line {
    border-bottom: 4px solid $baran-secondary;
    width: 60px;
  }

  &-text {
    margin: 0;
    padding: 0 1rem;
    position: relative;
    top: 8px;
    background: transparent;
    color: black;
    font-weight: bold;

    @media (max-width: 769px) {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
    @media (max-width: 481px) {
      font-size: 1.25rem;
    }
  }
}

.text-title {
  margin: 1rem 0;

  &-text {
    color: black;
    font-weight: bold;
    padding: 0;
  }
}

.text-frame-top-right {
  width: 100px;
  height: 100px;
  background: transparent;
  border-top: 3px solid $baran-primary;
  border-left: 3px solid $baran-primary;
  position: absolute;
  top: 0;
  left: 0;
}

.text-frame-top-left {
  width: 100px;
  height: 100px;
  background: transparent;
  border-top: 3px solid $baran-primary;
  border-right: 3px solid $baran-primary;
  position: absolute;
  top: 0;
  right: 0;
}

.service-modal {
  .modal-title {
    font-weight: bold;
  }

  td {
    padding: 0.72rem 1rem;
  }
}

.login-modal {
  .modal-title {
    font-weight: bold;
    margin: auto;
    width: 100%;
  }
}

.service-card {
  .card {
    box-shadow: none;
    width: 308px;
    margin: 0 auto;
    background: transparent;

    &:hover {
      .card-body {
        .top-border, .card-button {
          background: $baran-secondary !important;
          transition: background 1s;
        }

        .card-icon {
          animation: move 2s linear infinite alternate;
          border-color: $baran-secondary;
          transition: border-color 1s;
        }
      }
    }

    .card-body {
      margin: 0 1rem;
      padding: 1rem 1rem 0;
      background: $baran-white;
      position: relative;
      top: 0;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
      height: 250px;

      &-triangle {
        border-bottom: 40px solid $baran-white;
        border-right: 20rem solid transparent;
        height: 0;
        width: 15px;
        z-index: 2;
        position: absolute;
        top: -40px;
        right: 0;
      }

      .top-border {
        background: #00B1FF;
        transform: rotate(8deg);
        left: -0.15rem;
        position: absolute;
        top: -2.45rem;
        clip-path: polygon(0.5% 0, 100% 0, 99.5% 100%, 0 100%);
        width: 20.35rem;
        height: 15px;
        transition: background 1s;
      }

      .card-icon {
        background: $baran-white;
        position: absolute;
        z-index: 2;
        top: -4rem;
        left: 14rem;
        width: 70px;
        aspect-ratio: 1;
        display: grid;
        place-items: center;
        border-radius: 50%;
        border: 8px solid $baran-primary;
        transition: border-color 1s;

        img {
          max-width: 55px;
          max-height: 55px;
          border-radius: 50%;
        }
      }

      .card-title {
        color: black;
        font-weight: bold;
      }

      .card-text {
        height: 110px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: justify;
      }

      .card-button {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0;
        background: $baran-primary !important;
        width: 55%;
        border: none;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        height: 45px;
        transition: background 1s;

        &:hover {
          box-shadow: none;
        }

        &-triangle {
          position: absolute;
          bottom: 0;
          right: 0;
          border-top: 47px solid $baran-white;
          border-left: 2rem solid transparent;
        }
      }
    }
  }
}

.article-home-card {
  /*.card {
    flex-direction: row;
    border-radius: 100px;
    height: 150px;
    border: 1px solid $baran-primary;
    transition: border-color 1s;

    &:hover {
      border-color: $baran-secondary !important;
      transition: border-color 1s;

      !*.image-wrapper {
        background: $baran-secondary !important;
        transition: background 1s;
      }*!
    }

    .card-body {
      padding: 1rem;
      width: calc(100% - 150px);

      .card-title-wrapper {
        h5 {
          color: black;
        }

        span {
          color: $baran-gray;
          font-weight: normal;
          font-size: 15px;
        }

        .card-title {
          margin-bottom: 0;
          margin-right: 1rem;
          font-weight: bold;
          height: 43px;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: fit-content;
        }
      }

      .card-text {
        margin-right: 0.5rem;
        color: black !important;
        height: 65px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      width: 150px;
      background: $baran-primary;
      border-radius: 50%;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
      transition: background 1s;

      img {
        width: 85%;
        height: 85%;
        border-radius: 50%;
      }
    }
  }*/
}

.article-card {
  .card {
    box-shadow: none;
    width: 22rem;
    margin: 1rem 0;

    .image-wrapper {
      width: 22rem;
      height: 14.6rem;
      background: $baran-light;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 22rem;
        max-height: 22rem;
      }
    }

    .card-body {
      margin: 0 1rem;
      padding: 0 1rem;
      background: $baran-white;
      position: relative;
      top: -20px;
      outline: 2px solid $baran-primary;
      height: 250px;

      &-triangle {
        border-bottom: 30px solid $baran-white;
        border-right: 20.2rem solid transparent;
        height: 0;
        width: 15px;
        z-index: 2;
        position: absolute;
        top: -30px;
        right: -1px;
      }

      .top-border {
        background: #00B1FF;
        transform: rotate(6.1deg);
        left: -0.15rem;
        position: absolute;
        top: -1.25rem;
        clip-path: polygon(0.5% 0, 100% 0, 99.5% 100%, 0 100%);
        width: 20.2rem;
        height: 3px;
      }

      .right-border {
        position: absolute;
        top: -0.8rem;
        clip-path: polygon(0.5% 0, 100% 0, 99.5% 100%, 0 100%);
        height: 2px;
        background: $baran-primary;
        transform: rotate(90deg);
        width: 3rem;
        z-index: 2;
        left: -1.6rem;
      }

      .card-title {
        color: black;
        font-weight: bold;
        margin-bottom: 1rem !important;
      }

      .card-subtitle {
        //margin-bottom: 0.5rem !important;
        margin-top: 0;
      }

      .card-text {
        height: 110px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: justify;
      }

      .card-button {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0;
        background: $baran-primary !important;
        width: 55%;
        border: none;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        height: 45px;

        &:hover {
          box-shadow: none;
        }

        &-triangle {
          position: absolute;
          bottom: 0;
          right: 0;
          border-top: 50px solid $baran-white;
          border-left: 2rem solid transparent;
        }
      }
    }
  }
}

.swiper-container {
  width: 100%;

  .swiper-button-prev, .swiper-button-next {
    background: $baran-primary;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: $baran-white;

    @media (max-width: 481px) {
      width: 30px;
      height: 30px;

      &::after {
        font-size: 18px !important;
      }
    }

    &::after {
      font-size: 25px;
    }
  }

  .swiper-button-prev {
    left: 10px !important;
    right: auto !important;
  }

  .swiper-button-next {
    right: 10px !important;
    left: auto !important;
  }

}

.web-layout {
  background: $baran-white;

  .header {
    position: fixed;
    //top: 25px;
    //z-index: 10;
    right: 0;
    left: 0;
    width: 100%;
    background: transparent;
    align-items: center;
    //padding: 1.5rem 0;
    transition: 0.5s;
    z-index: 999;

    &-contact {
      background: $baran-primary;
      padding: 1rem;
      color: $baran-white;

      @media (max-width: 769px) {
        padding: 0.5rem;

        &-icon {
          width: 22px !important;
          height: 22px !important;
          display: flex;
          align-items: center;

          i {
            font-size: 18px !important;
          }
        }
      }

      &-icon {
        background: $baran-white;
        color: $baran-primary;
        padding: 4px;
        border-radius: 5px;
        margin: 0 0.5rem;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-content: center;

        i {
          font-size: 26px;
        }
      }
    }

    &-main {
      display: flex;
      padding: 0;
      position: fixed;
      top: 60px;
      width: 100%;
      z-index: 1030;
      transition: top 0.25s linear;
      align-items: center;

      @media (max-width: 769px) {
        top: 35px;
      }

      &.scroll-fixed {
        top: 0;
        box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.4);
        min-height: 64px;
        background: $baran-white;
      }

      &-logo {
        //width: 250px;
        padding: 0 0 0 1rem;
        background: $baran-primary;
        display: grid;
        justify-content: end;
        align-items: center;

        img {
          width: auto;
          max-width: 130px;
          @media (max-width: 900px) {
            max-width: 140px;
          }
        }
      }

      &-triangle {
        border-top: 64px solid $baran-primary;
        border-right: 30px solid $baran-white;
        height: 0;
        width: 15px;
      }

      &-menu {
        background: $baran-white;
        min-height: 64px;
        align-items: center;
        display: flex;
        flex: auto;

        .navbar {
          justify-content: start;
          padding: 0;

          a {
            color: $baran-primary;
            font-size: 16px;
            padding: 2px 10px;
            transition: 0.5s;

            &:not(:last-child) {
              border-right: 2px solid $baran-light;
            }

            &.active {
              font-weight: bolder;
              font-size: large;
            }
          }

          @media (max-width: 992px) {
            a {
              font-size: 18px !important;
            }
          }

          @media (max-width: 875px) {
            a {
              font-size: 16px !important;
            }
          }
          @media (max-width: 812px) {
            a {
              padding: 2px 10px;
              font-size: 14px !important;
            }
          }
        }
      }

      &-login {
        height: 64px;
        background: $baran-white;
        display: flex;
        align-items: center;
        padding-right: 1rem;

        button {
          background: $baran-primary !important;
          border-color: $baran-primary !important;
          width: fit-content;
          padding: 8px 15px;
          width: 135px;
        }
      }
    }

    &-mobile-main {
      display: flex;
      padding: 0;
      position: fixed;
      top: 36px;
      width: 100%;
      z-index: 1030;
      transition: top 0.25s linear;

      &.scroll-fixed {
        top: 0;
        box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }

  &-content {
    padding-top: 140px;
    min-height: calc(100vh - 260px - 1rem);

    @media (max-width: 769px) {
      padding-top: 100px;
    }
  }

  .footer {
    position: relative;
    background: $baran-primary;
    color: $baran-white;

    &-col {
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 20px;
        font-weight: bold;
        padding: 1rem;
        color: $baran-white;
        width: fit-content;

        @media (max-width: 769px) {
          margin: 0 auto;
        }
      }

      a {
        color: $baran-white;
        margin-bottom: 0.5rem;

        i {
          margin-right: 0.5rem;
        }
      }

      &-social-media {
        color: $baran-white;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 769px) {
          margin: 0 auto;
          justify-content: center;
        }

        &-icon {
          background: $baran-white;
          color: $baran-primary;
          padding: 4px;
          border-radius: 5px;
          margin: 0 1rem 0 0;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-content: center;

          i {
            font-size: 26px;
            margin: 0 !important;
          }
        }
      }
      &-Enemad{
        width: 100px;
        height: 100px;
        background: rgba(255, 255, 255, 0.09);
        border: 1px solid white;
        border-radius: 10px;
        backdrop-filter: blur(10px);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100%;
        }
      }
    }
  }
}


//pages

.home {
  overflow-x: hidden;

  .section-title {
    //margin: 2rem auto;
    padding: 3rem 4rem;

    @media (max-width: 769px) {
      margin: 1rem auto;
      padding: 2rem 1rem;
    }

    @media (max-width: 400px) {
      padding: 2rem 0;
    }
  }

  &-banners {
    margin-top: 1rem;
  }

  &-news-box {
    position: relative;

    @media (max-width: 769px) {
      margin-bottom: 1rem;

      &-text {
        &-primary {
          font-size: 16px !important;
          font-weight: bold;
          display: flex;
          align-items: center;
        }

        &-secondary {
          font-size: 12px !important;
          margin: 0 0.2rem;
          text-align: center;
          position: absolute;
          bottom: -1.5rem;
        }
      }
    }

    @media (max-width: 476px) {
      margin-bottom: 2rem;

      &-text {
        &-secondary {
          bottom: -2.5rem;
        }
      }
    }

    &-text {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      color: #01005D;
      display: grid;
      place-items: center;

      &-primary {
        font-size: 30px;
        margin-bottom: 0;
      }
    }
  }

  &-services {
    //padding-top: 2rem;
    width: 100%;
    background: rgba(203, 203, 203, 0.3);
    padding: 3rem 0 7rem;

    .swiper-container {
      width: 100%;
      margin: 0 auto !important;

      .swiper-button-prev, .swiper-button-next {
        top: 100px;

        @media (max-width: 481px) {
          width: 40px;
          height: 40px;

          &::after {
            font-size: 22px !important;
          }
        }
      }

      @media screen and (max-width: 768px) {
        padding: 1rem 0 !important;
        width: 308px;
        margin: 0 auto !important;
      }
    }

    &-slider {
      margin: 0 2rem;
      padding: 2rem 0;
    }
  }

  &-about {
    padding: 6rem 2rem 6rem;

    @media (max-width: 769px) {
      padding-top: 2rem !important;
    }

    @media (max-width: 400px) {
      padding: 2rem 0 !important;
    }

    &-text {
      @include paragraph(black, 18px);
    }

    &-left-button {
      position: absolute;
      right: 3rem;
    }

    &-video {
      width: 400px;
      height: fit-content;
      border-radius: 20px;
      margin-right: 1rem;

      @media (max-width: 809px) {
        height: 250px;
        margin: 0 0 1rem 0;
      }

      @media (max-width: 580px) {
        width: 90%;
        height: auto;
        margin: 0 0 1rem 0;
      }
    }
  }

  &-achievements {
    padding: 0 2rem;

    @media (max-width: 991px) {
      padding-bottom: 0 !important;
    }

    .w-45 {
      width: 45vw;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      margin-bottom: 2rem;
      line-height: 2;
      font-weight: bold;
      text-align: center;

      @media (max-width: 809px) {
        text-align: center !important;
      }

      @media (max-width: 769px) {
        font-size: 18px !important;
      }
    }

    /*&-image {
      height: auto;
      width: 100%;
      border-radius: 20px;

      @media (max-width: 991px) {
        width: 100%;
        height: auto;
      }
    }*/

    &-video {
      width: 100%;
      height: auto;
      border-radius: 20px;

      @media (max-width: 991px) {
        width: 100%;
        height: auto;
      }

      /*@media (max-width: 580px) {
        width: 90%;
        height: auto;
        margin: 0 0 1rem 0;
      }*/
    }

    .react-photo-album--row {
      img {
        border-radius: 10px;
      }
    }

    /*.position-relative {
      height: 350px;

      @media (max-width: 991px) {
        height: unset !important;
      }
    }

    &-absolute-1 {
      position: absolute;
      //left: 5rem;

      @media (max-width: 991px) {
        position: unset !important;
        left: unset !important;
      }
    }

    &-absolute-2 {
      position: absolute;
      right: 3rem;
      z-index: 2;
      bottom: -20rem;

      @media (max-width: 1260px) {
        bottom: -12rem !important;
      }

      @media (max-width: 991px) {
        position: unset !important;
        right: unset !important;
        bottom: unset !important;
        padding: 0 1rem !important;
        margin-top: 1rem;
      }
    }

    &-absolute-3 {
      position: absolute;
      left: -10rem;
      z-index: 10;
      bottom: -4rem;

      div {
        height: 320px;

        img {
          max-height: 100%;
          width: auto;
        }
      }

      @media (max-width: 991px) {
        position: unset !important;
        right: unset !important;
        bottom: unset !important;
        padding: 0 1rem !important;
        margin-top: 1rem;
      }
    }

    &-absolute-4 {
      position: absolute;
      left: 0;
      z-index: 2;
      top: -10rem;

      @media (max-width: 1260px) {
        top: 25rem;

      }

      div {
        height: 320px;

        img {
          max-height: 100%;
          width: auto;
        }
      }

      @media (max-width: 991px) {
        position: unset !important;
        right: unset !important;
        bottom: unset !important;
        padding: 0 1rem !important;
        margin-top: 1rem;
      }
    }*/
  }

  &-articles {
    width: 100%;
    background: rgba(203, 203, 203, 0.3);
    padding: 2rem 0;

    @media (max-width: 769px) {
      padding: 0 1rem 2rem;
      flex-direction: column;
    }

    &-more-button {
      border: 3px solid $baran-primary !important;
      border-radius: 0;
      background: none !important;
      color: $baran-primary !important;

      @media (max-width: 769px) {
        width: 250px;
      }

      &:focus {
        background: $baran-primary !important;
        color: $baran-white !important;
      }
    }

    .swiper-container {
      margin: 2rem 0 3rem;

      .swiper-slide {
        .article-home-card {
          //width: 250px !important;
          padding: 0;
          //margin: auto;

          .card {
            flex-direction: column;
            border-radius: 0;
            margin: 1rem auto;
            height: 300px;
            border: 0;
            border-bottom: 3px solid $baran-primary;
            width: 250px;

            &:hover {
              border-color: $baran-secondary;
            }

            .card-text {
              display: none !important;
            }

            .card-body {
              width: 100% !important;
              padding: 0.5rem;
              position: relative;

              .card-title-wrapper {
                flex-direction: column;
                margin: 0;

                h5 {
                  color: black;
                }

                span {
                  color: $baran-gray;
                  font-weight: normal;
                  font-size: 12px !important;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                }

                .card-title {
                  font-size: 15px;
                  margin: 0;
                  text-align: center;
                  font-weight: bold;
                  display: -webkit-box;
                  max-width: 100%;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  height: fit-content;
                }
              }
            }

            .image-wrapper {
              margin: 0;
              width: 100%;
              aspect-ratio: 1;
              max-height: 200px;
              border-radius: 0;
              box-shadow: none;
              display: flex;
              justify-content: center;
              align-items: start;
              background: none;

              img {
                border-radius: 0;
                max-width: 100%;
                max-height: 100%;
                width: unset !important;
                height: unset !important;
              }
            }
          }
        }
      }
    }
  }

  &-questions {
    padding: 4rem 2rem 1rem;

    @media (max-width: 400px) {
      padding: 2rem 0 !important;
    }

    &-box {
      border-left: 5px solid $baran-primary;
      padding: 0 1rem;
      margin-bottom: 2rem;

      &-icon {
        color: black;
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }

      &-question {
        @include paragraph(black, 20px);
        margin: 0;
      }

      &-answer {
        @include paragraph(black);
      }
    }

    &-more-button {
      border: 3px solid $baran-primary !important;
      border-radius: 0;
      background: none !important;
      color: $baran-primary !important;

      @media (max-width: 769px) {
        width: 250px;
      }

      &:focus {
        background: $baran-primary !important;
        color: $baran-white !important;
      }
    }
  }

  &-brands {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 0;

    @media (max-width: 769px) {
      padding: 0 1rem 2rem;
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;
      margin: 1.5rem;
      border-radius: 50%;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.8);
      outline: 1px solid $baran-primary;
      transition: 250ms outline ease-in-out;

      @media (max-width: 769px) {
        height: 90px;
        width: 90px;
        margin: 0.5rem !important;
      }

      @media (max-width: 481px) {
        height: 80px;
        width: 80px;
      }

      &:hover {
        outline: 5px solid $baran-primary;
        transition: 250ms outline ease-in-out;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &-slider {
      padding: 1rem;
      margin: 0 auto !important;

      @media (max-width: 769px) {
        //width: 100%;
        display: flex;
        justify-content: center;

        .swiper-slide {
          // margin: 0 1rem !important;
        }
      }

      .swiper-slide {
        width: fit-content;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.articles-page {
  padding: 0 2rem;

  &-wrapper {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 308px);
    grid-gap: 1rem;
    justify-content: center !important;
    flex: 1;
  }

  &-categories {
    width: 300px;
    height: fit-content;
    padding: 1rem;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
    //border-radius: 15px;
    background: $baran-light;
    margin-top: 1rem;

    label {
      font-size: 18px;
      width: 100%;
      text-align: center;
    }

    ul {
      margin-top: 1rem;

      a {
        color: $baran-gray !important;
      }
    }
  }

  .text-title {
    margin-bottom: 0;
  }

  &-date {
    margin-bottom: 2rem;
  }
}

.article-page {
  padding: 0 5rem;

  @media (max-width: 768px) {
    padding: 0 2rem;
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    background: $baran-primary;
    border-radius: 50%;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);

    img {
      width: 85%;
      height: 85%;
      border-radius: 50%;
    }
  }

  &-text {
    position: relative;
    padding: 2rem;


    p {
      text-align: justify;
      color: black;
    }
  }
}

.rules-page {
  padding: 0 5rem;
  overflow-x: hidden;

  @media (max-width: 1000px) {
    .image-wrapper {
      display: flex;
      justify-content: center;

      img {
        width: 250px !important;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 2rem;
  }

  @media (max-width: 481px) {
    .image-wrapper {
      img {
        width: 100% !important;
      }
    }
  }

  .row {
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .text-title {
    margin: 2rem 0;
  }

  &-text {
    position: relative;
    padding: 2rem;

    p, ul {
      @include paragraph();

      label {
        font-size: 25px;
        position: absolute;
        top: -24px;
        left: 105px;
        color: $baran-primary;
        font-weight: bold;
      }

      li {
        margin-bottom: 1rem;
      }
    }
  }

  .image-wrapper {
    img {
      width: -webkit-fill-available;
    }
  }
}

.about-page {
  padding: 0 5rem;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0 2rem;
  }

  .row {
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .text-title {
    margin: 2rem 0;
  }

  &-text {
    position: relative;
    padding: 2rem;


    p, ul {
      @include paragraph();

      label {
        font-size: 25px;
        position: absolute;
        top: -24px;
        left: 105px;
        color: $baran-primary;
        font-weight: bold;
      }

      li {
        margin-bottom: 1rem;
      }
    }
  }

  h3 {
    color: $baran-secondary;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }

  .image-wrapper {
    img {
      width: -webkit-fill-available;
    }
  }
}

.questions-page {
  padding: 0 5rem;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0 2rem;
  }

  &-box {
    border-left: 5px solid $baran-primary;
    padding: 0 1rem;
    margin-bottom: 2rem;

    &-icon {
      color: black;
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }

    &-question {
      @include paragraph(black, 20px);
      margin: 0;
    }

    &-answer {
      @include paragraph($baran-gray);
    }
  }
}

.contact-page {
  padding: 0 5rem;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0 2rem;

    .row {
      flex-direction: column-reverse;
    }
  }

  &-media {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 0.5rem;
    justify-content: space-between;
    margin-top: 3rem;

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 0 !important;

      &-item {
        &-bg {
          width: 120px !important;
        }

        i {
          font-size: 22px !important;
          top: 5px !important;
          right: 5px !important;
        }

        svg {
          width: 18px !important;
          height: 18px !important;
          top: 5px !important;
          right: 8px !important;
        }

        div img {
          width: 18px !important;
          height: 18px !important;
          top: 5px !important;
          right: 8px !important;
        }

        &-title {
          font-size: 10px !important;
          bottom: 40px !important;
        }

        &-text {
          font-size: 15px !important;
          height: 65px !important;
          align-items: end !important;

          &.text-small {
            font-size: 10px !important;
            word-break: break-word;
            padding: 0 1rem;
          }
        }
      }
    }

    &-item {
      position: relative;

      &-bg {
        width: 200px;
      }

      div img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 12px;
        right: 12px;
        color: white;
      }

      a {
        color: black;

        :hover {
          color: $baran-primary;
        }
      }

      i {
        font-size: 40px;
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
      }

      svg {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 12px;
        right: 12px;
        color: white;
      }

      &-title {
        position: absolute;
        bottom: 20px;
        justify-content: center;
        width: 100%;
        display: flex;
        font-weight: bold;
        font-size: 15px;
        color: $baran-primary;
      }

      &-text {
        position: absolute;
        top: 0;
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        color: black;

        &.text-small {
          font-size: 12px;
        }
      }
    }
  }

  &-map-frame {
    height: 350px;
    width: 650px;
    box-shadow: 0 0 10px #c1c1c1;
    border-radius: 20px;
    margin: 0;

    @media(max-width: 1450px) {
      width: 450px;
      height: 350px;
    }

    @media(max-width: 1250px) {
      width: 100%;
      height: 250px;
    }
  }

  &-call-request {
    display: flex;
    flex: auto;
    margin-right: 3rem;

    @media(max-width: 1250px) {
      margin: 1rem 0 0;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      &-form {
        margin: 1rem 0 0 !important;
      }

      &-image {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        img {
          width: 50% !important;
          height: auto !important;
        }

        &-title {
          text-align: start !important;
          line-height: 1.5;
        }
      }
    }

    &-image {
      height: auto;

      img {
        width: 350px;
        height: 350px;
        object-fit: contain;
      }

      &-title {
        color: black;
        font-weight: bold;
        text-align: center;
      }
    }

    &-form {
      width: 100%;
      margin-left: 2rem;

      i {
        font-size: 8px;
        margin-left: 2px;
      }

      button {
        width: 100%;
        margin-bottom: 0;
        margin-top: 1rem;
        border: 1px solid $baran-primary !important;
        color: $baran-primary !important;

        &:active {
          background: $baran-primary !important;
          color: $baran-white !important;
        }
      }
    }
  }
}

.collaboration-page, .employment-page {
  margin: 0 5rem;

  @media (max-width: 769px) {
    margin: 0 2rem !important;
  }

  &-wrapper {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    padding: 2rem;
    margin-bottom: 2rem;

    &-form {
      @media (max-width: 769px) {
        .submit-button {
          width: 100% !important;
          font-size: 15px !important;
        }


        label.pr-3, label.pr-2, label span.pr-2, label span.pr-3 {
          padding: 0 !important;
        }
      }

      @media (max-width: 481px) {
        .add-button {
          width: 92%;
        }
      }

      .invalid-feedback .text-underline {
        border-color: #ea5455;
      }

      .text-underline {
        border-bottom: 1px solid #5e5873;
      }

      .form-group {
        position: relative;
      }

      .empty-datepicker-btn {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        z-index: 999;
        background: none;

        &:focus {
          outline: none !important;
        }
      }

      .react-select {
        &.empty-select {
          .select__control {
            border-color: #ea5455;
          }
        }

        + .invalid-feedback {
          display: block;
          position: absolute;
          bottom: 0;
        }
      }

      .radio-wrapper {
        .invalid-feedback {
          top: 0 !important;
        }
      }

      .DatePicker {
        &.empty-date {
          .DatePicker__input {
            border-color: #ea5455;
          }
        }

        + .invalid-feedback {
          display: block;
          position: absolute;
          z-index: 2;
          top: 0.2rem !important;
        }
      }

      &-sections {
        section {
          padding: 2rem 0;

          &:first-child {
            padding-top: 0;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $baran-gray;
          }
        }

        .cancel-history {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          padding: 0;
          background: none;
          border: 0;
          color: inherit;

          &:active {
            outline: 0;
          }
        }

        .DatePicker {
          width: 100%;
          height: 38px;

          input {
            width: 100%;
            height: 100%;
            border-radius: 0.357rem;
            direction: ltr;
          }
        }
      }

      i {
        font-size: 8px;
        margin-left: 2px;
      }

      .submit-button {
        background: $baran-primary !important;
        color: $baran-white !important;
        font-size: 20px;
        padding: 1rem 0;
      }

      .custom-radio {
        margin-right: 1rem;
      }
    }
  }
}

.copyright {
  color: #5a5a5a;
  text-align: center;
  padding: 5px;
  margin-top: 35px;
  border-top: 2px solid $baran-primary;
  background: #ffffffd6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 0.25rem;
  }

  a {
    margin: 0 0.25rem;
    color: $baran-primary;
    font-weight: bold;
  }

  @media (max-width: 481px) {
    font-size: 10px;
  }

  @media (max-width: 769px) {
    font-size: 8px;
  }
}

.develop-in-progress {
  margin: 3rem 0;
  text-align: center;
  width: 100%;

  @media (max-width: 481px) {
    img {
      width: 80%;
    }

    .text-title-text {
      font-size: 15px !important;
    }
  }
}

//css for mobile-view
@media (max-width: 768px) {
  .pt-4, .pb-4, .pt-5 {
    padding: 1rem !important;
  }
  .my-5 {
    margin: 3rem 0 !important;
  }
  .my-4 {
    margin-top: 1rem !important;
  }
}

@keyframes move {
  0% {
    top: -4rem;
    right: 14rem;
  }
  50% {
    top: -5rem;
    right: 7rem;
  }
  100% {
    top: -6rem;
    right: 1rem;
  }
}

.flex-1 {
  flex: 1;
}
