// ================================================================================================
//     File Name: footers.scss
//     Description: *  Main footers styles.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Stick footers to the bottom of the page
footer {
  &.panel-footer {
    background: #ccc !important;
    color: $baran-primary;

    a {
      color: $baran-primary;

      &:hover {
        color: $baran-secondary;
      }
    }
  }

  &.footer {
    padding: 0.8rem $content-padding;
    min-height: $footer-height;
    transition: 300ms ease all;

    // heart icon scss
    span {
      i,
      svg {
        height: 0.8rem;
        width: 0.8rem;
        font-size: 0.8rem;
        margin-right: 0.5rem;
      }
    }

    .scroll-top {
      padding: 0.81rem 0.83rem;
    }
  }

  //shadow & border options
  &.footer-shadow {
    box-shadow: $box-shadow;
  }

  &.footer-border {
    border-top: 1px solid $border-color;
  }
}

// Fixed footers
.footer-fixed {
  footer {
    position: fixed !important;
    z-index: 1030;
    right: 0;
    left: 0;
    bottom: 0;

    // footers Light
    &.footer-light {
      background: $white;
      box-shadow: $box-shadow;
    }

    // footers Dark
    &.footer-dark {
      background: $gray-600;
      color: $white;
    }
  }
}

//! Fix: If footers is hidden add padding fix to avail card margin bottom
.footer-hidden {
  .app-content {
    padding-bottom: 0.1px !important;
  }
}

// scroll to Top footers Button
.scroll-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  display: none;
  z-index: 99;
}
