.media-list {
  .media {
    padding: 1.25rem;
    width: 100%;
    margin-top: 0;

    .media-left {
      margin-right: 1rem;
    }

    .media-right {
      margin-left: 1rem;
    }
  }

  a.media {
    color: $gray-600 !important;
  }
}

.media-bordered {
  .media:not(:first-child) {
    border-top: 1px solid $border-color;
  }
}
