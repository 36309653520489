@import "../scss/pallete.scss";

.fa-star-of-life {
  font-size: 8px;
  margin-left: 4px;
}

.react-select {
  &.empty-select {
    .select__control {
      border-color: #ea5455 !important;
    }
  }

  .select__single-value {
    height: 25px;
  }

  + .invalid-feedback {
    display: block;
    position: absolute;
    top: 0.2rem !important;
  }
}

.table {
  td {
    //padding: 0.72rem 0.1rem !important;
  }
}

.uppy-Container {
  + div {
    text-align: right;
  }
}

.uppy-FileInput-container {
  margin-bottom: 0 !important;
}

.uppy-FileInput-btn {
  border: 1px solid #14457f !important;
}

.select__control {
  border-color: #d8d6de !important;
}

form {
  .form-group {
    div:nth-child(2) {
      button {
        @media (max-width: 767px) {
          margin-top: 1rem;
        }
      }
    }
  }

  sup {
    top: -1.25rem !important;
  }

  .job-field {
    .invalid-feedback {
      top: 0;
    }
  }
}

.modal-body {
  .badge {
    @media (max-width: 767px) {
      margin: 0.25rem !important;
    }
  }
}

.table td button {
  @media (max-width: 767px) {
    margin: 0 0.25rem 0.25rem !important;
  }
}

.submit-button {
  background: $baran-success !important;
  border: 0 !important;

  /*@media (max-width: 767px) {
    margin: 0 1rem 1rem;
  }*/

  &:focus, &:active {
    background: $baran-success !important;
  }
}

.data-list-rows-dropdown {
  display: flex;
  justify-content: flex-end;

  .dropdown-menu {
    left: unset !important;
    margin-top: -0.5rem !important;
  }
}

form button {
  border: 0 !important;
}

.form-group {
  position: relative;

  .invalid-feedback {
    position: absolute;
    top: -1.5rem;
    right: 0.5rem;
    width: fit-content;
    font-size: 10px;
  }
}

.suggestion-text {
  text-align: justify;
}

.confirm-modal {
  .modal-header {
    .modal-title {
      font-weight: bold;
    }
  }

  &-ok-button {
    background: $baran-success !important;
    flex: 3;

    &:focus, &:active {
      background: $baran-success !important;
    }
  }

  &-cancel-button {
    background: $baran-error !important;
    flex: 1;

    &:focus, &:active {
      background: $baran-error !important;
    }
  }
}

.dashboard {
  &-news {
    &-actions {
      @media (max-width: 575px) {
        margin-top: 1rem;
      }

      button {
        border-radius: 50%;
        width: 30px;
        aspect-ratio: 1;
        padding: 0;
        border: none !important;
        margin: 0 0.5rem;
      }

      &-delete-button {
        background: $baran-error !important;

        &:focus, &:active {
          background: $baran-error !important;
        }
      }

      &-edit-button {
        background: $baran-info !important;

        &:focus, &:active {
          background: $baran-info !important;
        }
      }
    }

    &-modal {
      &-field {
        &-button {
          width: 100%;
          background: $baran-success !important;
          border: 0 !important;

          &:focus, &:active {
            background: $baran-success !important;
          }
        }
      }
    }
  }
}

.employment-request-modal {
  hr {
    margin-top: 0;
  }
}

// Menu Custom
.rst__rowContents {
  border: solid #bbb 1px !important;
}

.rst__lineChildren::after {
  content: unset !important;
}

.rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after {
  width: 0 !important;
}

.rst__lineHalfHorizontalRight::before {
  width: 0 !important;
}

// Menu End
// Product Category
.product-ul {
  height: 100px;
  overflow-y: scroll;
  list-style: none;
  box-shadow: 1px 1px 5px 2px #00000017;
  border-radius: 5px;
  padding: 10px;
}

.product-ul li {
  padding: 4px;
}

.product-ul li.active {
  border: 1px solid $baran-primary;
  border-radius: 5px;
}

//End Product Category

.delete-image-product {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  background: #e3edf2;
  border-radius: 20px;
}

.color-variety {
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 5px;

  div {
    cursor: pointer;
  }
}

.favorite-customer-dashboard-image {
  width: 75px;
  border-radius: 10px;
  box-shadow: 0 0 5px 1px #cdcdcd;
}

.favorite-customer-image {
  width: 120px;
  border-radius: 10px;
  box-shadow: 0 0 5px 1px #cdcdcd;
}

.customer-address {
  position: absolute;
  top: 5px;
  right: 10px;
}

.favorites-wrapper .remove-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none !important;
  border: none !important;
  color: red !important;
}

.parent-category {
  background: $baran-dark;
  padding: 10px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
}

.sub-category {
  cursor: pointer;
  background: #eeeeee;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;

  .parent-category {
    background: #eeeeee;
    color: #000000;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    margin: unset;
  }
}

// noty
.Toastify__toast-body {
  font-family: IRANSans, sans-serif;
}

//cardCourse
.btn-chatroom {
  background-color: $baran-primary !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  margin-left: 10px;
}

.btn-user-chatroom {
  background-color: $baran-secondary !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  margin-left: 10px;
}

.btn-teacher {
  background-color: $baran-primary !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  margin-left: 20px;
}

.footer.footer-static {
  z-index: 9999;
}

.collapse-users {
  border: 1px solid $baran-light;
  margin: 7px 0;
  border-radius: 6px;
  align-items: center;
  padding: 5px;
}

.img-chatroom {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.btn-users {
  background: $baran-light;
  width: 150px;
  margin: 0 15px;
  height: 35px;
  border-radius: 10px;
  color: #fff;
  border: none;
}

.btn-users:hover {
  background: #fff;
  color: $baran-light;
  border: 1px solid $baran-light;
}

.btn-users span {
  padding: 0 3px;
}

.code-course {
  background: $baran-light;
  position: relative;
  right: 1.8%;
  padding: 5px 20px;
  margin-top: -8 px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: white;
  box-shadow: 2px 0px 5px #ccc;
}

.code-course-danger {
  background: red;
  position: relative;
  right: 1.8%;
  padding: 5px 20px;
  margin-top: -8 px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: white;
  box-shadow: 2px 0px 5px #ccc;
}

.customer-courses {
  background: $baran-primary;
  position: relative;
  left: 1.8%;
  padding: 5px 20px;
  margin-top: -8 px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  color: white;
  box-shadow: 2px 0px 5px #ccc;
}

.dates {
  margin: 8px 0;
}

.dollar-color {
  background-color: #544179;
}

.lir-color {
  background-color: #6166B3;
}

.euro-color {
  background-color: #32C1CD;
}

.toman-color {
  background-color: #17D7A0;
}

.file-type {
  width: 40%;
  background: $baran-light;
  position: relative;
  right: 1.8%;
  padding: 5px 20px;
  margin-top: -8 px;
  border-radius: 25px;
  color: white;
  box-shadow: 2px 0 5px #ccc;
}

.favorites-wrapper .remove-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none !important;
  border: none !important;
  color: red !important;
}

.profile-badge {
  border: 2px solid #4facb4;
  padding: 5px;
  border-radius: 7px;

  .badge-image {
    width: 35px;
    border-radius: 50%;
  }
}
