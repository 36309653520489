$baran-primary: #0081B8;
$baran-primary-alpha: #72D4FF;
$baran-secondary: #D15D33;
$baran-white: #ffffff;
$baran-gray: #707070;
$baran-light: #E1E1E1;

$baran-dark: #2a2929;
$baran-success: #36b336;
$baran-error: #f83535;
$baran-info: #479eff;